// client-homepage/src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Layout/Header';
import MainVisual from './components/Home/MainVisual';
import Business from './components/Home/Business/Business';
import News from './components/Home/News/News';
import Product from './components/Home/Product';
import Recruit from './components/Home/Recruit';
import Company from './components/Home/Company';
import ContactForm from './components/Home/Contact/ContactForm';
import Footer from './components/Layout/Footer';
import PageTop from './components/shared/PageTop';
import ErucorePrivacy from './components/Erucore/Privacy';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/style.css';
import './styles/animation.css';
import './styles/inview.css';
import './App.css';

function App() {
  // Google Tag Manager
  React.useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WK79BRT');
    `;
    document.head.appendChild(script);

    // GTM noscript
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-WK79BRT";
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);

    // Cleanup
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="home">
            <div id="container">
              {/* ヘッダー */}
              <Header />
              {/* メインビジュアル */}
              <div className="mainimg">
                <MainVisual />
              </div>
              <main>
                <div className="bg1">
                  <Business />
                </div>
                <News />
                <Product />
                <Recruit />
                <Company />
                <ContactForm />
              </main>
              {/* フッター */}
              <Footer />
            </div>
            {/* ページの上部へ戻るボタン */}
            <PageTop />
          </div>
        } />
        <Route path="/erucore/privacy" element={<ErucorePrivacy />} />
      </Routes>
    </Router>
  );
}

export default App;