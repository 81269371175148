import React from 'react';
import './PrivacyPolicyDialog.css';

interface PrivacyPolicyDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PrivacyPolicyDialog: React.FC<PrivacyPolicyDialogProps> = ({
  isOpen,
  onClose
}) => {
  if (!isOpen) return null;

  return (
    <div className="dialog-overlay" onClick={onClose}>
      <div className="dialog-content" onClick={e => e.stopPropagation()}>
        <div className="dialog-header">
          <h2>プライバシーポリシー</h2>
        </div>
        <div className="dialog-body">
          <div className="privacy-policy-content">
            <h2>1. はじめに</h2>
            <ul>
              <li>本ウェブサイトは、株式会社カスケード（以下「当社」）の事業内容等を紹介するサイトです。</li>
              <li>当社は個人情報保護の重要性を認識し、適切な取り扱いを実施します。</li>
            </ul>

            <h2>2. 個人情報の取り扱い</h2>
            
            <h3>2.1 収集する個人情報</h3>
            <ul>
              <li>お名前</li>
              <li>e-mailアドレス</li>
              <li>電話番号</li>
              <li>その他お問い合わせ時にご提供いただく情報</li>
            </ul>

            <h3>2.2 利用目的</h3>
            <ul>
              <li>お問い合わせへの回答</li>
              <li>当社からのご連絡</li>
              <li>業務のご案内</li>
              <li>資料のご送付</li>
            </ul>

            <h3>2.3 第三者提供</h3>
            <p>以下の場合を除き、個人情報を第三者に開示いたしません：</p>
            <ol>
              <li>お客さまの同意がある場合</li>
              <li>業務委託先への必要な開示</li>
              <li>法令に基づく開示が必要な場合</li>
            </ol>

            <h2>3. 安全管理措置</h2>

            <h3>3.1 実施する対策</h3>
            <ul>
              <li>セキュリティシステムの維持</li>
              <li>管理体制の整備</li>
              <li>社員教育の徹底</li>
              <li>不正アクセス防止</li>
              <li>データの紛失・破損・改ざん・漏洩の防止</li>
            </ul>

            <h3>3.2 個人情報の正確性確保</h3>
            <ul>
              <li>個人情報を正確かつ最新の状態に保持</li>
              <li>セキュリティ対策の実施</li>
              <li>定期的な安全管理措置の見直し</li>
            </ul>

            <h2>4. お客さまの権利</h2>

            <h3>4.1 個人情報の照会・修正・削除</h3>
            <ul>
              <li>ご本人確認後、個人情報の照会・修正・削除に対応いたします。</li>
            </ul>

            <h2>5. 法令遵守</h2>
            <ul>
              <li>日本の個人情報保護法及び関連法令の遵守</li>
              <li>プライバシーポリシーの定期的な見直しと改善</li>
            </ul>

            <h2>6. お問い合わせ先</h2>
            <p className="contact-info">
              <strong>株式会社カスケード</strong><br />
              住所：大阪府大阪市淀川区西中島5丁目6-13新大阪御幸ビル4階<br />
              部署：サポート担当<br />
              Email：contact@cascade.co.jp
            </p>

            <p className="policy-date">制定日：2024年11月21日</p>
          </div>
        </div>
      </div>
    </div>
  );
};